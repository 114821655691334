import { Box, Flex } from "@chakra-ui/react";
import { SwiperSlide } from "swiper/react";
import { PhotoAttribute } from "~/components/Text";
import { useNavbarHeight } from "~/lib/hooks";

export const ThirdPanel = () => {
  const navbarHeight = useNavbarHeight();

  return (
    <Flex
      as={SwiperSlide}
      w={{ base: "250vw", md: "150vw", lg: "95vw" }}
      h="auto"
      backgroundColor="blue.400"
      //hides weird half pixel line between these 2 slides;
      boxShadow="0px 0px 0px 1px #60A9DA"
      justifyContent={"space-between"}
    >
      <Box
        w={{ base: "50%", md: "40%" }}
        display="flex"
        flexDir="column"
        pt={navbarHeight}
        mr={{ base: 0, md: -50 }}
      >
        <Flex
          mb={{ base: "20px", md: "70px" }}
          w={{ base: "140%" }}
          h={"100%"}
          borderRadius={{ base: "16px", md: "22px" }}
          bgImage="/CommunitiesImage.jpg"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          align="flex-end"
          justify="flex-start"
          shadow="-10px 10px 10px 2px rgba(10, 10, 9, 0.14)"
          transform="rotate(3deg)"
        >
          {/* <PhotoAttribute>Katie Goldie</PhotoAttribute> */}
        </Flex>
      </Box>
      <Flex
        bgImage="/home.jpg"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        flexGrow={1}
        maxW={{ base: "390px", md: "715px" }}
        alignItems="flex-end"
        justifyContent="flex-end"
        backgroundPosition="center"
      >
        <PhotoAttribute>Keri Medig</PhotoAttribute>
      </Flex>
    </Flex>
  );
};

ThirdPanel.displayName = "SwiperSlideThirdPanel";
