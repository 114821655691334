import { Link } from "@chakra-ui/next-js";
import { Box, Button, Heading, VStack, Image } from "@chakra-ui/react";
import { Subheading } from "../Text";
import { SwiperSlide } from "swiper/react";

export const SixthPanel = () => {
  return (
    <Box
      as={SwiperSlide}
      w="1220px"
      h="auto"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      bgColor="gray.900"
    >
      <VStack w="50%" h="auto" display="flex" justifyContent="center">
        <StoriesIntro />
        <Link href={"/stories"}>
          <Button backgroundColor="yellow.500" color="gray.900" m="0px 80px">
            Start journey
          </Button>
        </Link>
      </VStack>

      <Box
        w="50%"
        h="auto"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box pt="160px" m="32px">
          <Image src="/homeShield.png" alt="Road Block" h="370px" />
        </Box>
      </Box>
    </Box>
  );
};

SixthPanel.displayName = "SwiperSlideSixthPanel";

const StoriesIntro = () => {
  return (
    <Box
      bg="gray.900"
      pb={{ base: "72px" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={{ base: "56px", md: "12px" }}
      >
        <Heading
          textAlign="center"
          color="yellow.500"
          fontSize={{ base: "6xl", md: "8xl", lg: "6xl" }}
          as="span"
        >
          Stories
        </Heading>
        <Heading
          textAlign="center"
          color="white"
          fontSize={{ base: "6xl", md: "8xl", lg: "6xl" }}
          fontWeight="thin"
          as="span"
        >
          from the road
        </Heading>
      </Box>

      <Subheading
        textAlign="center"
        fontSize={{ base: "lg", md: "2xl" }}
        color="white"
        w="50%"
      >
        Learn more about what makes highway 3 unique, and get inspired for your
        next trip
      </Subheading>
    </Box>
  );
};
