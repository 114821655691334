import { Box, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import { SwiperSlide } from "swiper/react";
import { useWindowSize } from "usehooks-ts";
import { CommunitiesMap } from "~/components/CommunitiesMap";
import { useNavbarHeight } from "~/lib/hooks";

export const FifthPanel = () => {
  const { height } = useWindowSize();
  const showPopup = useBreakpointValue({ base: false, md: true });
  const navbarHeight = useNavbarHeight();
  return (
    <Flex
      as={SwiperSlide}
      w="auto"
      h="auto"
      backgroundColor="white"
      position="relative"
      bgImg="/MapTexture.png"
      backgroundSize="cover"
      justify={"space-between"}
    >
      <Box h="100%" pt={navbarHeight} pb={10} pl={24}>
        <CommunitiesMap
          showPopup={showPopup}
          text={
            "Follow the route & click to learn more about each community on highway 3"
          }
        />
      </Box>
      <Image
        src={"/tabletHomeRoadBlock.png"}
        alt="Road Block"
        objectFit={"contain"}
        objectPosition={"right"}
        h={height}
      />
    </Flex>
  );
};

FifthPanel.displayName = "SwiperSlideFifthPanel";
