import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import { ItineratorItineraryList } from "@itinerator/next-package";
import { SwiperSlide } from "swiper/react";
import { RoadTripLink } from "~/components/Buttons";
import { Subheading } from "~/components/Text";
import { useNavbarHeight } from "~/lib/hooks";

type PropsFourthPanel = {
  itineraries: ItineratorItineraryList;
};
export const FourthPanel = ({ itineraries }: PropsFourthPanel) => {
  const navbarHeight = useNavbarHeight();

  return (
    <Flex
      as={SwiperSlide}
      w={{ base: "130vw", md: "90vw", lg: "70vw" }}
      h="auto"
      justifyContent="center"
      bgColor="sage.500"
      pt={navbarHeight}
      zIndex={1}
    >
      <Flex
        w="60%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          w={{ base: "70%", lg: "70%" }}
          borderBottom="3px solid"
          borderColor="green.500"
        >
          <Subheading
            fontSize={{ base: "xl" }}
            ml={{ base: "40px", md: "104px" }}
            pb={{ base: "24px" }}
          >
            Plan your next journey with our themed road trips
          </Subheading>
        </Box>
        <Box
          h="60%"
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          ml={{ base: "40px", md: "104px" }}
        >
          {itineraries.map((roadtrip) => (
            <Flex key={roadtrip.id} alignItems="center">
              <Heading fontSize="2xl" color="yellow.500">
                *
              </Heading>
              <Flex
                pl="20px"
                display="flex"
                alignItems={"flex-start"}
                sx={{
                  _hover: { pl: 10 },
                }}
              >
                <RoadTripLink
                  fontFamily={"heading"}
                  href={`/roadtrips/${roadtrip.slug}`}
                  fontSize={{ base: "md", md: "2xl" }}
                  fontWeight="bold"
                  color="white"
                >
                  {roadtrip.name}
                </RoadTripLink>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
      <Box w="40%" display="flex" alignItems="center">
        <Image src="/mobileHomeShieldGroup.png" alt="sheilds" ml={8} />
      </Box>
    </Flex>
  );
};

FourthPanel.displayName = "SwiperSlideFourthPanel";
